import React from "react"

import TextHero, {
  VideoHero,
  VideoReleaseHero,
  CharacterHero,
  PostHero,
  NewsHero,
  SeasonsHero,
} from "@components/Heroes"
import DefaultImagePanel, {
  DefaultVideoPanel,
  AltVideoPanel,
  DefaultVideoThumbnail,
  DefaultVideoBasicPanel,
} from "@components/Panels"
import PreRegister from "@components/PreRegister"
import SimplePreRegister from "@components/SimplePreRegister"
import Incentives from "@components/Incentives"
import CharactersCarousel, {
  FeaturedCharactersCarousel,
  DownloadsCarousel,
  ArenasCarousel,
  ClassTypesCarousel,
  PreRegisterRewardsCarousel,
  SeasonRewardsCarousel,
} from "@components/Carousels"
import ThumbnailColumns from "@components/Columns"
import DefaultImageCta, { AltImageCta } from "@components/ImageCta"
import {
  VideoGrid,
  SocialGrid,
  CharacterGrid,
  NewsGrid,
  PerksGrid,
} from "@components/Grids"
import LatestNews from "@components/LatestNews"
import FeaturedNews from "@components/FeaturedNews"
import PostBody from "@components/PostBody"
import PostShare from "@components/PostShare"
import MediaGallery from "@components/Galleries"
import {
  CharacterAbilities,
  CharacterCustomisation,
  CharacterRelatedMedia,
} from "@components/Character"
import ProgramOverview from "@components/ProgramOverview"
import TextWithMedia from "@components/TextWithMedia"
import Faqs from "@components/Faqs"
import SeasonPassShowcase from "@components/SeasonPassShowcase"
import PreRegisterWithIncentives from "../PreRegisterWithIncentives"
import PlatformRewards from "../PlatformRewards"

const getBladeType = ({ key, model, ...props }) => {
  const type = model?.apiKey

  switch (type) {
    case "hero_video":
      return <VideoHero {...{ key, ...props }} />
    case "release_hero_video":
      return <VideoReleaseHero {...{ key, ...props }} />
    case "hero_text":
      return <TextHero {...{ key, ...props }} />
    case "hero_season":
      return <SeasonsHero {...{ key, ...props }} />
    case "hero_character":
      return <CharacterHero {...{ key, ...props }} />
    case "post_hero":
      return <PostHero {...{ key, ...props }} />
    case "hero_news":
      return <NewsHero {...{ key, ...props }} />

    case "panel_image":
      return <DefaultImagePanel {...{ key, ...props }} />
    case "panel_video":
      return <DefaultVideoPanel {...{ key, ...props }} />
    case "panel_basic_video":
      return <DefaultVideoBasicPanel {...{ key, ...props }} />
    case "season_pass_showcase":
      return <SeasonPassShowcase {...{ key, ...props }} />
    case "panel_thumbnail":
      return <DefaultVideoThumbnail {...{ key, ...props }} />

    case "simple_pre_registration":
      return <SimplePreRegister {...{ key, ...props }} />
    case "pre_registration":
      return <PreRegister {...{ key, ...props }} />
    case "incentive":
      return <Incentives {...{ key, ...props }} />
    case "preregister_with_incentive":
      return <PreRegisterWithIncentives {...{ key, ...props }} />
      return <div></div>
    case "platform_reward":
      return <PlatformRewards {...{ key, ...props }} />
    case "carousel_season_reward":
      return (
        <SeasonRewardsCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_pre_register_reward":
      return (
        <PreRegisterRewardsCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_character":
      return (
        <CharactersCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_featured_character":
      return (
        <FeaturedCharactersCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_download":
      return (
        <DownloadsCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_arena":
      return (
        <ArenasCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_class_type":
      return (
        <ClassTypesCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_arena":
      return (
        <ArenasCarousel
          {...{
            key,
            ...props,
          }}
        />
      )

    case "carousel_character":
      return (
        <CharactersCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_featured_character":
      return (
        <FeaturedCharactersCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_download":
      return (
        <DownloadsCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_arena":
      return (
        <ArenasCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_class_type":
      return (
        <ClassTypesCarousel
          {...{
            key,
            ...props,
          }}
        />
      )
    case "carousel_arena":
      return (
        <ArenasCarousel
          {...{
            key,
            ...props,
          }}
        />
      )

    case "columns_thumbnail":
      return (
        <ThumbnailColumns
          {...{
            key,
            ...props,
          }}
        />
      )

    case "gallery_media_block":
      return (
        <MediaGallery
          {...{
            key,
            ...props,
          }}
        />
      )

    case "grid_video":
      return <VideoGrid {...{ key, ...props }} />
    case "grid_social":
      return <SocialGrid {...{ key, ...props }} />
    case "grid_character":
      return <CharacterGrid {...{ key, ...props }} />
    case "grid_news":
      return <NewsGrid {...{ key, ...props }} />
    case "grid_perk":
      return <PerksGrid {...{ key, ...props }} />

    case "call_to_action_image":
      return <DefaultImageCta {...{ key, ...props }} />
    case "call_to_action_image_alt":
      return <AltImageCta {...{ key, ...props }} />

    case "latest_news":
      return <LatestNews {...{ key, ...props }} />

    case "featured_news":
      return <FeaturedNews {...{ key, ...props }} />

    case "post_body":
      return <PostBody {...{ key, ...props }} />
    case "post_share":
      return <PostShare {...{ key, ...props }} />

    case "character_ability":
      return <CharacterAbilities {...{ key, ...props }} />
    case "character_customisation":
      return <CharacterCustomisation {...{ key, ...props }} />
    case "character_related_media_showcase":
      return <CharacterRelatedMedia {...{ key, ...props }} />

    case "program_overview":
      return <ProgramOverview {...{ key, ...props }} />

    case "text_with_media_block":
      return <TextWithMedia {...{ key, ...props }} />

    case "faq":
      return <Faqs {...{ key, ...props }} />
  }
}
const Blades = ({
  blades,
  post,
  allPosts = [],
  allCharacters = [],
  currentCharacter = {},
  classTypes = [],
  allArenas = [],
  locale = "en-GB",
  globalConfig = {},
}) =>
  blades.map((blade, i) =>
    getBladeType({
      key: i,
      ...blade,
      post,
      allPosts,
      allCharacters,
      currentCharacter,
      allClassTypes: classTypes,
      allArenas,
      locale,
      globalConfig,
    })
  )

export default Blades
