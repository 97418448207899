import React from "react"
import BackgroundImage from "gatsby-background-image"

import geometricPatternLight from "@images/animations/geometric-patterns/geometric-pattern-light.json"

import Link from "@components/Link"
import Lottie from "@components/Lottie"

import "./style.scss"

const AltImageCta = ({
  heading,
  description,
  backgroundImage,
  showButton,
  link,
  buttonLabel,
  locale,
  scrollToBladeId,
}) => (
  <div id={scrollToBladeId} className="alt-image-cta lg:-mt-16 overflow-hidden">
    <BackgroundImage
      fluid={backgroundImage?.fluid}
      className="relative bg-lightgrey bg-norepeat text-center lg:text-left bg-cover bg-right lg:bg-center bg-norepeat"
      style={{ position: "relative" }}
    >
      <div className="lg:hidden w-full h-full absolute top-0 left-0 bg-lightgrey1 bg-opacity-50 z-10" />
      <div className="lg:pt-16 relative z-20">
        <div className="w-full h-full absolute top-0 left-0 overflow-hidden">
          <Lottie
            animation={geometricPatternLight}
            className="w-double md:w-full lg:w-3/5 h-auto absolute top-1/2 left-1/3 transform -translate-x-1/2 -translate-y-1/2 opacity-30 z-0 pointer-events-none select-none overflow-hidden"
            loop={true}
          />
        </div>
        <div className="lg:container mx-auto px-8 lg:px-20 xl:px-32">
          <div className="py-20 pb-28 md:py-32 lg:py-48 xl:py-56">
            <h2 className="max-w-sm mb-4 md:mb-8 mx-auto lg:mx-0 relative text-4xl md:text-5xl lg:texl-6xl text-darkgrey2 font-display tracking-wide font-bold italic uppercase">
              {heading}
            </h2>
            <div
              className="max-w-md mx-auto lg:mx-0 font-display text-lg font-light text-darkgrey2"
              dangerouslySetInnerHTML={{ __html: description }}
            />
            {showButton && link?.slug && buttonLabel ? (
              <Link
                to={
                  link?.slug === "home"
                    ? locale === "en-GB"
                      ? "/"
                      : `/${locale?.toLowerCase()}`
                    : locale === "en-GB"
                    ? `/${link?.slug}`
                    : `/${locale?.toLowerCase()}/${link?.slug}`
                }
                type="submit"
                className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 mt-8 relative transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded z-30 transition"
              >
                <span className="transform skew-x-12">{buttonLabel}</span>
              </Link>
            ) : null}
          </div>
        </div>
      </div>
    </BackgroundImage>
  </div>
)

export default AltImageCta
