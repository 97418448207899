import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import cx from 'classnames'
import React, { useEffect, useState } from 'react'
import publicIp from 'public-ip'
import { useStaticQuery, graphql } from 'gatsby'
import Email from '@components/Forms/Email'
import Text from '@components/Forms/Text'
import MultipleSelect from '@components/Forms/MultipleSelect'
import Select from '@components/Forms/Select'

import './style.scss'

const statuses = {
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
    INVALID: 'invalid',
}

const GetAssets = () => {
    return useStaticQuery(graphql`
        query getPreRegisterWithIncentivesAssets {
            aurebesh: file(
                relativePath: {
                    eq: "global/aurebesh/shapes/aurebesh-shape-01-yellow.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 648, formats: [AUTO, WEBP])
                }
            }
            smudge: file(
                relativePath: { eq: "global/textures/smudges/smudge-03.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 620, formats: [AUTO, WEBP])
                }
            }
            background: file(
                relativePath: { eq: "global/triangles/triangles-dark.png" }
            ) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                    }
                }
            }
        }
    `)
}

const mapMultiSelect = (value, options) => {
    return value.length > 0 ? value?.map((i) => options?.[i]?.value) : []
}
const PreRegisterBlock = ({
    locale,
    title,
    description,
    date,
    mediaItems,
    qrCodeText,
    qrCodeImage,
    platformIcon,
    googlePlayButtonLink,
    googlePlayButtonImage,
    iosButtonLink,
    iosButtonImage,
    formTitle,
    submitLabel,
    privacyLabel,
    loadingMessage,
    successMessage,
    invalidMessage,
    alreadyRegisteredMessage,
    mustOptInMessage,
    invalidEmailAddressMessage,
    genericErrorMessage,
    globalConfig,
}) => {
    const [email, setEmail] = useState('')
    const [accepted, setAccepted] = useState(false)
    const [status, setStatus] = useState(statuses.PENDING)
    const [invalid, setInvalid] = useState([])
    const [errorCode, setErrorCode] = useState(null)
    const { smudge, background } = GetAssets()

    const { emailLabel } = globalConfig

    useEffect(() => {
        if ([statuses.ERROR].includes(status)) setStatus(statuses.PENDING)
    }, [email])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const _invalid = []

        if (
            [statuses.LOADING, statuses.ERROR, statuses.SUCCESS]?.includes(
                status
            )
        )
            return false

        if (!accepted) {
            setStatus(statuses.INVALID)

            if (!accepted) _invalid.push('accepted')
            setInvalid(_invalid)

            return false
        } else {
            setInvalid([])
        }

        const clientIp = await publicIp.v4()
        setStatus(statuses.LOADING)

        let response = await fetch('/api/pre-register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fields: {
                        email_address: email,
                        locale,
                        browser_language:
                            navigator?.languages &&
                            navigator?.languages?.length > 0
                                ? navigator?.languages?.[0]
                                : navigator?.language ||
                                  navigator?.browserLanguage ||
                                  null,
                        time_zone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        client_ip: clientIp,
                        opt_out: false,
                    },
                }),
            }),
            data = await response.json()

        if (data?.success) {
            setStatus(statuses.SUCCESS)
        } else {
            setStatus(statuses.ERROR)
            if (data?.code) setErrorCode(data?.code)
        }
    }
    return (
        <div className=" lg:container mx-auto px-8 lg:px-20 xl:px-32 relative z-50 ">
            <div className="relative z-50 px-1.5 md:px-8 lg:px-12 border-l border-r border-darkgrey1">
                <div className="w-full rounded-xl relative -top-8 xshort:-top-20 short:-top-40 tall:-top-60 xtall:-top-80 z-50 overflow-hidden">
                    {/* <h2 className="block text-center mb-4 lg:hidden text-lightblue uppercase blue-text-glow italic text-4xl text-stroke-blue">
            {date}
          </h2> */}
                    <div className="bg-offBlack grid grid-cols-12 gap-4 gap-y-0 milestones-clip">
                        <div className="hidden lg:flex px-4 lg:px-8 pt-6 md:pt-8 lg:pt-0 my-auto col-span-12 lg:col-span-7 gap-2 flex-col justify-center items-center w-full">
                            <h2 className=" text-lightblue text-center uppercase blue-text-glow italic text-5xl text-stroke-blue font-bold">
                                {date}
                            </h2>
                        </div>
                        <div className="relative p-4 py-10 bg-offBlack lg:bg-transparent  my-auto col-span-12 lg:col-span-5 flex flex-col items-center justify-center">
                            <div className="w-0.5 bg-darkgrey2 absolute left-0 top-0 h-full hidden lg:block"></div>
                            <h2 className="hidden lg:block uppercase text-center text-orange2 text-lg">
                                {qrCodeText}
                            </h2>
                            <div className="hidden lg:block">
                                <GatsbyImage
                                    image={getImage(qrCodeImage)}
                                    className=" h-auto w-32"
                                />
                            </div>
                            <div className="flex gap-4 mt-4 flex-wrap items-center justify-center">
                                <a
                                    href={googlePlayButtonLink}
                                    type="button"
                                    className="flex-0 lg:hidden inline-flex items-center justify-center ml-2 transform -skew-x-12 hover:scale-105 bg-darkgrey2 hover:bg-transparent font-display font-bold text-2xl text-white hover:text-offBlack border-2 border-darkgrey2 tracking-wide uppercase italic rounded transition"
                                >
                                    <GatsbyImage
                                        image={getImage(googlePlayButtonImage)}
                                        className="w-36"
                                    />
                                </a>
                                <a
                                    href={iosButtonLink}
                                    type="button"
                                    className="flex-0 lg:hidden inline-flex items-center justify-center ml-2 transform -skew-x-12 hover:scale-105 bg-darkgrey2 hover:bg-transparent font-display font-bold text-2xl text-white hover:text-offBlack border-2 border-darkgrey2 tracking-wide uppercase italic rounded transition"
                                >
                                    <GatsbyImage
                                        image={getImage(iosButtonImage)}
                                        className="w-36"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="border-t-2 border-darkgrey2 col-span-12 grid grid-cols-1 py-4 md:py-8 gap-8">
                            <div className=" my-auto col-span-1 group lg:w-full text-center flex flex-col justify-center items-center relative">
                                <div className=" flex flex-col justify-center items-center border-2 py-2 w-[95%] sm:w-[80%] xl:w-3/5 2xl:w-1/2 rounded border-blue shadow-blue relative">
                                    <div className="w-16 h-1 absolute rounded-sm shadow-blue -top-1 right-16 bg-lightblue" />
                                    <div className="w-16 h-1 absolute rounded-sm shadow-blue -bottom-1 left-16 bg-lightblue" />
                                    <div
                                        className={`w-6 h-6 absolute -top-1 -right-1 border-t-4 border-r-4 border-lightblue rounded transition-all`}
                                    />
                                    <div
                                        className={`w-6 h-6 absolute -left-1 -bottom-1 border-l-4 border-b-4 border-lightblue rounded transition-all `}
                                    />
                                    <h2 className="text-lightblue uppercase italic text-3xl xl:text-4xl blue-text-glow text-stroke-blue font-bold">
                                        {title}
                                    </h2>
                                    <p className="uppercase text-white text-xl blue-text-glow font-semibold">
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div className=" col-span-1 flex flex-wrap gap-2 px-2 lg:gap-6 justify-center items-center">
                                {mediaItems.map((image, i) => (
                                    <div
                                        className={cx(
                                            ' lg:col-span-1 relative h-16 w-16 lg:h-20 lg:w-20 border-2 border-blue flex justify-center items-center rounded-xl blue-box-glow shadow-blue',
                                            {
                                                'lg:mr-0': i % 2 === 0,
                                                'lg:ml-0': i % 2 !== 0,
                                            }
                                        )}
                                    >
                                        <span
                                            className={`incentives__block-wing block w-1 lg:w-1.5 h-8 absolute bg-blue top-1/2 right-full transform -translate-y-1/2`}
                                        />
                                        <GatsbyImage
                                            image={getImage(image)}
                                            className="w-full h-full absolute rounded-xl"
                                            objectFit="cover"
                                            objectPosition="center"
                                        />
                                        <span
                                            className={`incentives__block-wing block w-1 lg:w-1.5 h-8 absolute bg-blue top-1/2 left-full transform -translate-y-1/2`}
                                        />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="bg-darkgrey1 min-h-60 px-2 md:px-6 lg:px-12 py-8 pt-6 lg:pt-8 grid grid-cols-12 gap-4">
                        <div className="col-span-12">
                            {status !== statuses.SUCCESS ? (
                                <form
                                    id="pre-register-form"
                                    className="max-w-4xl mx-auto font-display text-sm text-center text-white font-light"
                                    onSubmit={handleSubmit}
                                >
                                    <div className="flex flex-col md:flex-row items-center">
                                        <div className="flex-1 flex flex-col lg:flex-row gap-4 lg:gap-0 justify-center lg:justify-start items-center w-full md:w-auto relative">
                                            <Email
                                                required
                                                id="email"
                                                name="email"
                                                placeholder={emailLabel}
                                                value={email}
                                                setValue={({ value }) =>
                                                    setEmail(value)
                                                }
                                                classNames={{
                                                    container:
                                                        'flex-1 w-full pr-6',
                                                }}
                                            />
                                            <button
                                                type="submit"
                                                className="flex-0 inline-flex items-center justify-center py-4 max-w-sm px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                                            >
                                                <span className="transform skew-x-12">
                                                    {submitLabel}
                                                </span>
                                            </button>
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row items-center justify-start mt-0 lg:mt-12 text-center md:text-left">
                                        <div className=" mt-8 md:mt-0">
                                            <label
                                                htmlFor="privacy"
                                                className="flex items-center cursor-pointer text-left"
                                                onClick={() =>
                                                    setAccepted(!accepted)
                                                }
                                            >
                                                <span
                                                    className={`flex-0 flex items-center justify-center mr-8 p-2 border-2 ${
                                                        invalid?.includes(
                                                            'accepted'
                                                        )
                                                            ? 'border-orange3'
                                                            : 'border-white'
                                                    } rounded`}
                                                >
                                                    <span
                                                        className={`block w-4 h-4 ${
                                                            accepted
                                                                ? 'bg-regalblue'
                                                                : 'bg-transparent'
                                                        }`}
                                                    />
                                                </span>
                                                <span
                                                    className={`pre-register__privacy block max-w-xl relative ${
                                                        invalid?.includes(
                                                            'accepted'
                                                        )
                                                            ? 'text-orange3'
                                                            : 'text-white'
                                                    }`}
                                                    dangerouslySetInnerHTML={{
                                                        __html: privacyLabel,
                                                    }}
                                                ></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div
                                        className={`${
                                            status === statuses.LOADING
                                                ? 'block'
                                                : 'hidden'
                                        } mt-12 font-display font-bold text-2xl text-orange2 uppercase italic`}
                                    >
                                        {loadingMessage}
                                    </div>
                                    <div
                                        className={`${
                                            status === statuses.ERROR
                                                ? 'block'
                                                : 'hidden'
                                        } mt-12 font-display font-bold text-2xl text-orange2 uppercase italic`}
                                    >
                                        {errorCode ? (
                                            <>
                                                {parseInt(errorCode) === 4001
                                                    ? alreadyRegisteredMessage
                                                    : null}
                                                {parseInt(errorCode) === 4002
                                                    ? mustOptInMessage
                                                    : null}
                                                {parseInt(errorCode) === 4003
                                                    ? invalidEmailAddressMessage
                                                    : null}
                                                {parseInt(errorCode) === 4004 ||
                                                parseInt(errorCode) === 999
                                                    ? genericErrorMessage
                                                    : null}
                                            </>
                                        ) : (
                                            genericErrorMessage
                                        )}
                                    </div>
                                    <div
                                        className={`${
                                            status === statuses.INVALID
                                                ? 'block'
                                                : 'hidden'
                                        } mt-12 -my-2 font-display font-bold text-2xl text-orange2 uppercase italic`}
                                    >
                                        {invalid?.includes('email') ? (
                                            <p className="py-2">
                                                {invalidEmailAddressMessage}
                                            </p>
                                        ) : null}

                                        {invalid?.includes('accepted') ? (
                                            <p className="py-2">
                                                {mustOptInMessage}
                                            </p>
                                        ) : null}
                                    </div>
                                </form>
                            ) : (
                                <>
                                    <div
                                        className={`max-w-4xl mx-auto ${
                                            status === statuses.SUCCESS
                                                ? 'block'
                                                : 'hidden'
                                        } mt-12 font-display font-bold text-2xl text-orange2 text-center uppercase italic`}
                                    >
                                        {successMessage}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PreRegisterBlock
