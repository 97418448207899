import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import cx from "classnames"
import dotLinesYellow02 from "@images/animations/dot-lines/yellow-02.json"
import corner from "@images/global/corner-sm.svg"
import arrowRight from "@images/global/arrow-right.svg"
import letters01 from "@images/animations/aurebesh/letters-huttball-01.json"
import letters02 from "@images/animations/aurebesh/letters-control.json"
import letters03 from "@images/animations/aurebesh/letters-escort.json"
import aurebesh1 from "@images/animations/aurebesh/letters-05.json"
import dashedCircleLight from "@images/global/dashed-circle-light.svg"

import SeasonPassCard from "./SeasonPassCard"
import Background from "@components/Background"

import Lottie from "@components/Lottie"
import SVG from "@components/SVG"

import "./style.scss"

const GetAssets = () => {
  return useStaticQuery(graphql`
    query getSeasonPassShowcaseAssets {
      grungeLight: file(
        relativePath: { eq: "global/textures/grunge/grunge-light-01.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 480, formats: [AUTO, WEBP])
        }
      }
      specklesLight2: file(
        relativePath: { eq: "global/textures/speckles/speckles-light-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      specklesLight3: file(
        relativePath: { eq: "global/textures/speckles/speckles-light-03.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      smudge2: file(
        relativePath: { eq: "global/textures/smudges/smudge-02.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 577, formats: [AUTO, WEBP])
        }
      }
      aurebesh: file(
        relativePath: {
          eq: "global/aurebesh/shapes/aurebesh-shape-04-gray.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(width: 806, formats: [AUTO, WEBP])
        }
      }
    }
  `)
}

const SeasonPassShowcase = ({
  globalConfig,
  heading,
  buttonLabel,
  seasonPassCards,
  backgroundImage,
  questions = [],
  scrollToBladeId,
}) => {
  const { grungeLight, specklesLight2, specklesLight3, aurebesh } = GetAssets()

  return seasonPassCards?.length > 0 ? (
    <Background
      fluid={backgroundImage?.fluid}
      id={scrollToBladeId}
      className="faqs md:-mt-8 lg:-mt-16 md:pt-8 lg:pt-16 relative z-50 overflow-hidden bg-darkgrey3"
    >
      <span className="w-full h-full absolute top-0 left-0 bg-gradient-to-b from-darkgrey3  to-[rgba(55,55,54,0.7)]" />

      <GatsbyImage
        image={getImage(grungeLight)}
        className="w-112 h-auto absolute top-0 right-20 opacity-85 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(specklesLight3)}
        className="w-112 h-auto absolute top-60 left-60 opacity-85 z-30 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <GatsbyImage
        image={getImage(specklesLight2)}
        className="w-112 h-auto absolute bottom-20 left-60 opacity-85 z-0 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <Lottie
        animation={aurebesh1}
        className="w-40 mx-auto mb-4 absolute top-[5.5rem] right-20 md:right-60 lg:right-80"
        loop={true}
      />
      <GatsbyImage
        image={getImage(aurebesh)}
        className="hidden lg:block w-20 h-auto absolute top-40 -left-2 opacity-100 z-10 pointer-events-none select-none"
        style={{ position: "absolute" }}
        alt="Star Wars: Hunters"
      />
      <div className="lg:container mx-auto -mb-8 lg:-mb-16 px-8 lg:px-20 xl:px-32">
        <div className="border-l border-r border-darkgrey1 border-opacity-20 relative">
          <span
            className="h-28 w-2 transform border-2 border-orange3 absolute top-0 -left-6
          "
          ></span>

          <SVG
            src={dashedCircleLight}
            className="w-10 h-auto absolute top-52 left-4"
          />
          <span className="block w-px h-16 lg:h-32 bg-darkgrey1 absolute top-0 -right-px transform -translate-y-full opacity-20" />
          <span className="block w-px h-16 lg:h-32 bg-darkgrey1 absolute top-0 -left-px transform -translate-y-full opacity-20" />

          <div className="pt-8 md:pt-12 pb-20 md:pb-32 lg:pb-48 relative">
            <div className="flex flex-col lg:flex-row items-center justify-between text-center lg:text-left border-t border-darkgrey1 border-opacity-20">
              {heading ? (
                <h2 className=" text-center pt-12 md:pt-16 mx-auto mb-8 text-4xl md:text-5xl  text-white font-display tracking-wide font-bold italic uppercase">
                  <Lottie
                    animation={dotLinesYellow02}
                    className="w-8 mx-auto mb-4 relative"
                    loop={true}
                  />
                  {heading}
                </h2>
              ) : null}
            </div>

            <div className="p-12 relative  z-50 grid grid-cols-2 gap-12">
              {seasonPassCards?.map(
                (
                  {
                    tag,
                    heading,
                    content,
                    image,
                    buttonText,
                    buttonLink,
                    hasButton,
                  },
                  i
                ) => (
                  <div className="flex flex-col justify-center items-center odd:md:items-end even:md:items-start col-span-2 md:col-span-1">
                    <SeasonPassCard
                      {...{
                        tag,
                        heading,
                        content,
                        image,
                        buttonText,
                        buttonLink,
                        hasButton,
                      }}
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Background>
  ) : null
}

export default SeasonPassShowcase
