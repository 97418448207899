import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { format, parseISO } from 'date-fns'

import corner from '@images/global/corner.svg'
import next from '@images/global/next.svg'

import Link from '@components/Link'
import SVG from '@components/SVG'

import functions from '@functions'
import { functions as _functions } from '@config'

import './style.scss'

const { truncate, getDateFormat } = functions
const { processPath } = _functions

const SplitNewsCard = ({
    slug,
    tags: _tags,
    category,
    title,
    thumbnail,
    showDate = false,
    meta,
    locale,
}) => {
    return (
        <Link
            to={processPath(slug, 'en-GB', 'post')}
            className="group flex flex-1 flex-col min-h-full h-full relative transition-all"
        >
            {category && category.categoryName ? (
                <>
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow z-20"
                    />
                    <div className="split-news-card__tag inline-flex self-start items-center justify-center px-4 pr-16 h-10 -mb-px bg-blue text-white font-display font-light uppercase rounded-tl-xl z-0">
                        {category.categoryName}
                    </div>
                </>
            ) : null}
            <div className="flex flex-col relative h-full">
                <div className="mb-4 relative h-full">
                    <div
                        className={`news-clip-box__box ${
                            showDate ? 'with-date' : 'default'
                        } min-h-full h-full flex-1 flex flex-col sm:flex-row md:flex-col xl:flex-row rounded-xl ${
                            category && category.categoryName
                                ? 'rounded-tl-none'
                                : 'rounded-tl-xl'
                        } overflow-hidden`}
                    >
                        <div className="w-full sm:w-3/5 md:w-full xl:w-3/5 pt-full xl:pt-9/16 relative bg-lightgrey3 overflow-hidden">
                            <GatsbyImage
                                image={thumbnail?.gatsbyImageData}
                                className="w-full h-full absolute md:relative xl:absolute top-0 left-0 transform group-hover:scale-105 transition-all bg-cover bg-center bg-norepeat"
                                style={{ position: 'absolute' }}
                            />
                        </div>
                        <div className="flex flex-col items-start w-full sm:w-2/5 md:w-full xl:w-2/5 p-8 bg-white text-left h-full justify-between sm:justify-start md:justify-between">
                            <span>
                                {meta?.firstPublishedAt ? (
                                    <span className="block font-light text-lg text-blue">
                                        {format(
                                            parseISO(meta?.firstPublishedAt),
                                            getDateFormat(locale)
                                        )}
                                    </span>
                                ) : null}
                                <span
                                    className="font-display text-2xl md:text-lg lg:text-xl 2xl:text-3xl font-bold tracking-wide text-darkgrey2 uppercase italic"
                                    dangerouslySetInnerHTML={{
                                        __html: truncate(title),
                                    }}
                                />
                            </span>
                            <Link
                                to="/"
                                className="inline-flex items-center justify-center py-4 px-4 mt-4 xl:mt-8 bg-orange1 group-hover:bg-transparent text-white group-hover:text-orange1 transform -skew-x-12 border-2 border-orange1 font-display font-bold text-2xl tracking-wide uppercase italic rounded"
                            >
                                <span className="skew-x-12">
                                    <SVG src={next} className="w-8 h-auto" />
                                </span>
                            </Link>
                        </div>
                    </div>
                    {!category || !category.categoryName ? (
                        <SVG
                            src={corner}
                            className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                        />
                    ) : null}
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                    />
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                    />
                    <SVG
                        src={corner}
                        className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                    />
                </div>
            </div>
        </Link>
    )
}

export default SplitNewsCard
