import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import publicIp from 'public-ip'
import cx from 'classnames'

import corner from '@images/global/corner.svg'
import letters2 from '@images/animations/aurebesh/letters-02.json'
import letters5 from '@images/animations/aurebesh/letters-05.json'

import { useLightboxState } from '@context'

import Background from '@components/Background'
import { Play as PlayButton } from '@components/Buttons'
import Link from '@components/Link'
import SVG from '@components/SVG'
import Lottie from '@components/Lottie'
import Email from '@components/Forms/Email'

import './style.scss'
import ReactMarkdown from 'react-markdown'

const statuses = {
    PENDING: 'pending',
    LOADING: 'loading',
    SUCCESS: 'success',
    ERROR: 'error',
    INVALID: 'invalid',
}

const GetAssets = () => {
    return useStaticQuery(graphql`
        query getDefaultVideoPanelAssets {
            aurebesh: file(
                relativePath: {
                    eq: "global/aurebesh/shapes/aurebesh-shape-03-regalblue.png"
                }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 190, formats: [AUTO, WEBP])
                }
            }
            speckles: file(
                relativePath: { eq: "global/textures/speckles/speckles-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 520, formats: [AUTO, WEBP])
                }
            }
            grunge: file(
                relativePath: { eq: "global/textures/grunge/grunge-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 480, formats: [AUTO, WEBP])
                }
            }
            smudge: file(
                relativePath: { eq: "global/textures/smudges/smudge-01.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(width: 620, formats: [AUTO, WEBP])
                }
            }
            background: file(
                relativePath: { eq: "global/triangles/triangles-dark.png" }
            ) {
                childImageSharp {
                    fluid {
                        aspectRatio
                        base64
                        sizes
                        src
                        srcSet
                        srcSetWebp
                        srcWebp
                    }
                }
            }
        }
    `)
}

const DefaultVideoPanel = ({
    heading,
    description,
    video,
    showButton,
    link,
    buttonLabel,
    backgroundImage,
    thumbnail,
    scrollToBladeId,

    showDownloadOverlay = false,
    locale,
    downloadHeading = 'Download now',
    downloadLink = '',
    downloadSubheading = 'Sign-up to our news network',
    submitLabel = 'Submit label',
    privacyLabel = 'Click to consent to pre-register and receive news and updates for Star Wars: Hunters™ and other games owned by Zynga and its subsidiaries. You can opt out by clicking the Unsubscribe link in any email. For details about how your personal data is treated, see the Privacy Policy.',
    loadingMessage = 'Loading message',
    successMessage = 'Success message',
    alreadyRegisteredMessage = 'Already reigstered message',
    mustOptInMessage = 'Must opt in message',
    invalidEmailAddressMessage = 'Invalid email address message',
    genericErrorMessage = 'Generic error message',
    googlePlayLogo,
    googlePlayLink,
    appStoreLogo,
    appStoreLink,
    nintendoLogo,
    nintendoLink,
    globalConfig,
}) => {
    const { setLightboxVideo, setLightboxOpen } = useLightboxState()
    const { aurebesh, speckles, grunge, background } = GetAssets()
    const [loaded, setLoaded] = useState(false)
    const [email, setEmail] = useState('')
    const [accepted, setAccepted] = useState(false)
    const [status, setStatus] = useState(statuses.PENDING)
    const [invalid, setInvalid] = useState([])
    const [errorCode, setErrorCode] = useState(null)

    const { emailLabel } = globalConfig

    useEffect(() => {
        if ([statuses.ERROR].includes(status)) setStatus(statuses.PENDING)
    }, [email])

    const handleSubmit = async (e) => {
        e.preventDefault()
        const _invalid = []

        if (
            [statuses.LOADING, statuses.ERROR, statuses.SUCCESS]?.includes(
                status
            )
        )
            return false

        if (!accepted) {
            setStatus(statuses.INVALID)

            if (!accepted) _invalid.push('accepted')
            setInvalid(_invalid)

            return false
        } else {
            setInvalid([])
        }

        const clientIp = await publicIp.v4()
        setStatus(statuses.LOADING)

        let response = await fetch('/api/pre-register', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    fields: {
                        email_address: email,
                        locale,
                        browser_language:
                            navigator?.languages &&
                            navigator?.languages?.length > 0
                                ? navigator?.languages?.[0]
                                : navigator?.language ||
                                  navigator?.browserLanguage ||
                                  null,
                        time_zone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        client_ip: clientIp,
                        opt_out: false,
                    },
                }),
            }),
            data = await response.json()

        if (data?.success) {
            setStatus(statuses.SUCCESS)
        } else {
            setStatus(statuses.ERROR)
            if (data?.code) setErrorCode(data?.code)
        }
    }

    const handleVideoClick = () => {
        setLightboxVideo(video)
        setLightboxOpen(true)
    }

    useEffect(() => {
        setLoaded(true)
    })

    return (
        <div className="w-screen relative">
            <Lottie
                animation={letters5}
                className="w-48 h-auto mx-auto absolute top-8 lg:top-12 left-8 md:left-12 lg:left-16 z-40 pointer-events-none select-none opacity-50 overflow-hidden"
                loop={true}
            />
            <Lottie
                animation={letters2}
                className="hidden md:block w-28 h-auto mx-auto absolute bottom-8 lg:bottom-12 right-4 lg:right-12 xl:right-16 z-40 pointer-events-none select-none opacity-50 overflow-hidden"
                loop={true}
            />

            <div className="video-panel relative">
                <div className="w-full lg:w-[calc(100vw-24rem]) h-full absolute top-0 right-0">
                    <GatsbyImage
                        image={getImage(backgroundImage)}
                        className="w-full h-full absolute top-0 right-0 bg-black bg-cover bg-center bg-no-repeat text-center lg:text-left"
                        style={{ position: 'absolute' }}
                    />
                </div>
                <GatsbyImage
                    image={getImage(grunge)}
                    className="w-80 h-auto absolute -top-20 -left-20 opacity-50 z-40 pointer-events-none select-none"
                    style={{ position: 'absolute' }}
                    alt="Star Wars: Hunters"
                />
                <GatsbyImage
                    image={getImage(aurebesh)}
                    className="hidden xl:block w-20 h-auto absolute top-1/2 left-0 transform -translate-y-1/2 z-40 opacity-0 xl:opacity-100 invisible xl:visible pointer-events-none select-none"
                    style={{ position: 'absolute' }}
                    alt="Star Wars: Hunters"
                />

                <div className="lg:container mx-auto lg:px-20 xl:px-32">
                    {showDownloadOverlay ? (
                        <div className="w-full max-w-6xl mx-auto lg:px-20 xl:px-32 lg:-mb-[40rem] relative transform lg:-translate-y-1/2 z-[50] text-center">
                            <div className="download-overlay relative">
                                <GatsbyImage
                                    image={getImage(speckles)}
                                    className="w-80 h-auto absolute top-0 right-1/2 transform translate-x-1/3 opacity-50 z-30 pointer-events-none select-none"
                                    style={{ position: 'absolute' }}
                                    alt="Star Wars: Hunters"
                                />

                                <a
                                    href={downloadLink}
                                    target="_blank"
                                    rel="noopener nofollow noreferrer"
                                    className="group/button flex items-center justify-center lg:h-36 py-5 lg:py-0 relative bg-offBlack lg:rounded-t-xl z-30"
                                >
                                    <div className=" flex flex-col justify-center items-center border-2 px-6 py-4 rounded border-orange3 shadow-orange3 group-hover/button:bg-orange3 relative">
                                        <div className="w-16 h-1 absolute rounded-sm shadow-orange3 -top-1 right-16 bg-orange3" />
                                        <div className="w-16 h-1 absolute rounded-sm shadow-orange3 -bottom-1 left-16 bg-orange3" />
                                        <div
                                            className={`w-6 h-6 absolute -top-1 -right-1 border-t-4 border-r-4 border-orange3 rounded transition-all`}
                                        />
                                        <div
                                            className={`w-6 h-6 absolute -left-1 -bottom-1 border-l-4 border-b-4 border-orange3 rounded transition-all `}
                                        />

                                        <div className="text-orange3 group-hover/button:text-offBlack uppercase italic text-3xl xl:text-4xl orange3-text-glow text-stroke-orange3 font-bold">
                                            {downloadHeading}
                                        </div>
                                    </div>
                                </a>

                                <div className="bg-darkgrey1 lg:rounded-b-xl overflow-hidden">
                                    <div className="flex flex-col md:flex-row items-center justify-center py-4 lg:py-0 px-6 lg:h-36 relative z-40">
                                        {googlePlayLogo ? (
                                            <div className="flex-1 w-full lg:w-auto py-4 px-4">
                                                <a
                                                    href={googlePlayLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer nofollow"
                                                    className="group/button flex items-center justify-center max-w-xs lg:h-20 mx-auto lg:mx-0 relative"
                                                >
                                                    <span className="block w-full lg:w-[calc(100%+0.25rem)] h-full absolute top-0 left-1/2 transform -translate-x-1/2 -skew-x-12 group-hover/button:scale-[1.05] border-2 border-offBlack rounded-lg group-hover/button:opacity-0 transition-all ease-in-out"></span>
                                                    <div className="flex lg:inline-flex items-center justify-center w-[calc(100%-0.5rem)] lg:h-[4.25rem] m-1.5 lg:m-0 py-4 lg:py-0 relative transform -skew-x-12 text-white">
                                                        <span className="block w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover/button:scale-110 bg-offBlack rounded transition-all ease-in-out"></span>

                                                        <GatsbyImage
                                                            image={getImage(
                                                                googlePlayLogo
                                                            )}
                                                            className="w-3/4 max-w-[10rem] md:max-w-[14rem] lg:max-w-[10rem] h-auto transform skew-x-12"
                                                        />
                                                    </div>
                                                </a>
                                            </div>
                                        ) : null}

                                        {appStoreLogo ? (
                                            <div className="flex-1 w-full lg:w-auto py-4 px-4">
                                                <a
                                                    href={appStoreLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer nofollow"
                                                    className="group/button flex items-center justify-center max-w-xs lg:h-20 mx-auto lg:mx-0 relative"
                                                >
                                                    <span className="block w-full lg:w-[calc(100%+0.25rem)] h-full absolute top-0 left-1/2 transform -translate-x-1/2 -skew-x-12 group-hover/button:scale-[1.05] border-2 border-offBlack rounded-lg group-hover/button:opacity-0 transition-all ease-in-out"></span>
                                                    <div className="flex lg:inline-flex items-center justify-center w-[calc(100%-0.5rem)] lg:h-[4.25rem] m-1.5 lg:m-0 py-4 lg:py-0 relative transform -skew-x-12 text-white">
                                                        <span className="block w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover/button:scale-110 bg-offBlack rounded transition-all ease-in-out"></span>

                                                        <GatsbyImage
                                                            image={getImage(
                                                                appStoreLogo
                                                            )}
                                                            className="w-3/4 max-w-[10rem] md:max-w-[14rem] lg:max-w-[10rem] h-auto transform skew-x-12"
                                                        />
                                                    </div>
                                                </a>
                                            </div>
                                        ) : null}

                                        {nintendoLogo ? (
                                            <div className="flex-1 w-full lg:w-auto py-4 px-4">
                                                <a
                                                    href={nintendoLink}
                                                    target="_blank"
                                                    rel="noopener noreferrer nofollow"
                                                    className="group/button flex items-center justify-center max-w-xs lg:h-20 mx-auto lg:mx-0 relative"
                                                >
                                                    <span className="block w-full lg:w-[calc(100%+0.25rem)] h-full absolute top-0 left-1/2 transform -translate-x-1/2 -skew-x-12 group-hover/button:scale-[1.05] border-2 border-offBlack rounded-lg group-hover/button:opacity-0 transition-all ease-in-out"></span>
                                                    <div className="flex lg:inline-flex items-center justify-center w-[calc(100%-0.5rem)] lg:h-[4.25rem] m-1.5 lg:m-0 py-4 lg:py-0 relative transform -skew-x-12 text-white">
                                                        <span className="block w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover/button:scale-110 bg-offBlack rounded transition-all ease-in-out"></span>

                                                        <GatsbyImage
                                                            image={getImage(
                                                                nintendoLogo
                                                            )}
                                                            className="w-3/4 max-w-[10rem] md:max-w-[14rem] lg:max-w-[10rem] h-auto transform skew-x-12"
                                                        />
                                                    </div>
                                                </a>
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="flex flex-col items-center justify-center lg:h-[22rem] pb-20 lg:pb-0 pt-8 lg:pt-0 px-8 relative border-t border-offBlack border-opacity-20 overflow-hidden">
                                        <Background
                                            fluid={
                                                background?.childImageSharp
                                                    ?.fluid
                                            }
                                            id="download-background"
                                            className="download-background w-full h-full !absolute top-1/2 left-0 -translate-y-1/4 !pointer-events-none !select-none"
                                            style={{
                                                position: 'absolute!important',
                                            }}
                                        />

                                        <div className="min-h-60 px-2 md:px-6 lg:px-12 grid grid-cols-12 gap-4 relative z-40">
                                            <div className="col-span-12 flex flex-col justify-center items-center">
                                                {status !== statuses.SUCCESS ? (
                                                    <form
                                                        id="pre-register-form"
                                                        className="max-w-4xl mx-auto font-display text-sm text-center text-white font-light"
                                                        onSubmit={handleSubmit}
                                                    >
                                                        <h2 className="max-w-md mx-auto mb-4 uppercase font-medium text-lg md:text-xl text-center text-orange2 relative z-20 tracking-wider">
                                                            {downloadSubheading}
                                                        </h2>

                                                        <div className="flex flex-col md:flex-row items-center">
                                                            <div className="flex-1 flex flex-col lg:flex-row gap-4 lg:gap-0 justify-center lg:justify-start items-center w-full md:w-auto relative">
                                                                <Email
                                                                    required
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder={
                                                                        emailLabel
                                                                    }
                                                                    value={
                                                                        email
                                                                    }
                                                                    setValue={({
                                                                        value,
                                                                    }) =>
                                                                        setEmail(
                                                                            value
                                                                        )
                                                                    }
                                                                    classNames={{
                                                                        container:
                                                                            'flex-1 w-full pr-6',
                                                                    }}
                                                                />
                                                                <button
                                                                    type="submit"
                                                                    className="flex-0 inline-flex items-center justify-center py-4 max-w-sm px-8 ml-2 transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded transition"
                                                                >
                                                                    <span className="transform skew-x-12">
                                                                        {
                                                                            submitLabel
                                                                        }
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>

                                                        <div className="flex flex-col md:flex-row items-center justify-start md:mt-8 text-center md:text-left">
                                                            <div className="mt-8 md:mt-0">
                                                                <label
                                                                    htmlFor="privacy"
                                                                    className="flex items-center cursor-pointer text-left"
                                                                    onClick={() =>
                                                                        setAccepted(
                                                                            !accepted
                                                                        )
                                                                    }
                                                                >
                                                                    <span
                                                                        className={`flex-0 flex items-center justify-center mr-8 p-2 border-2 ${
                                                                            invalid?.includes(
                                                                                'accepted'
                                                                            )
                                                                                ? 'border-orange3'
                                                                                : 'border-white'
                                                                        } rounded`}
                                                                    >
                                                                        <span
                                                                            className={`block w-4 h-4 ${
                                                                                accepted
                                                                                    ? 'bg-regalblue'
                                                                                    : 'bg-transparent'
                                                                            }`}
                                                                        />
                                                                    </span>
                                                                    <ReactMarkdown
                                                                        className={`pre-register__privacy block max-w-xl relative ${
                                                                            invalid?.includes(
                                                                                'accepted'
                                                                            )
                                                                                ? 'text-orange3'
                                                                                : 'text-white'
                                                                        }`}
                                                                    >
                                                                        {
                                                                            privacyLabel
                                                                        }
                                                                    </ReactMarkdown>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={`${
                                                                status ===
                                                                statuses.LOADING
                                                                    ? 'block'
                                                                    : 'hidden'
                                                            } mt-4 font-display font-bold text-lg text-orange2 uppercase italic`}
                                                        >
                                                            {loadingMessage}
                                                        </div>
                                                        <div
                                                            className={`${
                                                                status ===
                                                                statuses.ERROR
                                                                    ? 'block'
                                                                    : 'hidden'
                                                            } mt-4 font-display font-bold text-lg text-orange2 uppercase italic`}
                                                        >
                                                            {errorCode ? (
                                                                <>
                                                                    {parseInt(
                                                                        errorCode
                                                                    ) === 4001
                                                                        ? alreadyRegisteredMessage
                                                                        : null}
                                                                    {parseInt(
                                                                        errorCode
                                                                    ) === 4002
                                                                        ? mustOptInMessage
                                                                        : null}
                                                                    {parseInt(
                                                                        errorCode
                                                                    ) === 4003
                                                                        ? invalidEmailAddressMessage
                                                                        : null}
                                                                    {parseInt(
                                                                        errorCode
                                                                    ) ===
                                                                        4004 ||
                                                                    parseInt(
                                                                        errorCode
                                                                    ) === 999
                                                                        ? genericErrorMessage
                                                                        : null}
                                                                </>
                                                            ) : (
                                                                genericErrorMessage
                                                            )}
                                                        </div>
                                                        <div
                                                            className={`${
                                                                status ===
                                                                statuses.INVALID
                                                                    ? 'block'
                                                                    : 'hidden'
                                                            } mt-4 font-display font-bold text-lg text-orange2 uppercase italic`}
                                                        >
                                                            {invalid?.includes(
                                                                'email'
                                                            ) ? (
                                                                <p className="py-2">
                                                                    {
                                                                        invalidEmailAddressMessage
                                                                    }
                                                                </p>
                                                            ) : null}

                                                            {invalid?.includes(
                                                                'accepted'
                                                            ) ? (
                                                                <p className="py-2">
                                                                    {
                                                                        mustOptInMessage
                                                                    }
                                                                </p>
                                                            ) : null}
                                                        </div>
                                                    </form>
                                                ) : (
                                                    <>
                                                        <div
                                                            className={`max-w-md mx-auto ${
                                                                status ===
                                                                statuses.SUCCESS
                                                                    ? 'block'
                                                                    : 'hidden'
                                                            } font-display font-bold text-2xl md:text-3xl text-orange2 text-center uppercase italic`}
                                                        >
                                                            {successMessage}
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <span className="block w-[0.75rem] h-1/4 bg-darkgrey1 absolute bottom-1/4 right-full"></span>
                            </div>
                        </div>
                    ) : null}

                    <div className="flex flex-col lg:flex-row items-center relative">
                        <div
                            className={`video-panel__panel static lg:relative w-full lg:w-96 md:px-10 py-12 pt-20 ${
                                showDownloadOverlay ? 'lg:pt-[30rem]' : ''
                            } lg:py-48 px-8 lg:pl-0 lg:pr-12 bg-lightgrey1 lg:bg-white`}
                        >
                            <GatsbyImage
                                image={getImage(speckles)}
                                className="w-80 h-auto absolute top-0 right-1/2 transform translate-x-1/3 opacity-50 z-30 pointer-events-none select-none"
                                style={{ position: 'absolute' }}
                                alt="Star Wars: Hunters"
                            />

                            <div className="video-panel__line w-px absolute bottom-0 left-8 lg:left-0 bg-darkgrey1 bg-opacity-20 z-30 pointer-events-none" />
                            <h2 className="mb-4 md:mb-8 relative text-center lg:text-left text-4xl md:text-5xl lg:texl-6xl text-darkgrey1 font-display font-bold tracking-wide italic uppercase z-30">
                                {heading}
                            </h2>
                            <div
                                className="max-w-lg mx-auto lg:max-w-full lg:mx-0 relative font-display text-center lg:text-left text-lg font-light text-darkgrey1 z-30"
                                dangerouslySetInnerHTML={{
                                    __html: description,
                                }}
                            />
                            {showButton && link?.slug && buttonLabel ? (
                                <Link
                                    to={link?.slug}
                                    type="submit"
                                    className="flex-0 inline-flex items-center justify-center py-4 px-8 ml-2 mt-8 relative transform -skew-x-12 hover:scale-105 bg-blue hover:bg-transparent font-display font-bold text-2xl text-white hover:text-blue border-2 border-blue tracking-wide uppercase italic rounded z-30 transition"
                                >
                                    <span className="transform skew-x-12">
                                        {buttonLabel}
                                    </span>
                                </Link>
                            ) : null}
                            <div className="video-panel__hr w-full h-px mt-12 absolute left-0 bottom-10 md:bottom-16 lg:bottom-24 bg-lightgrey2 z-30 pointer-events-none" />
                        </div>
                        <div
                            className={`video-panel__video flex-1 w-full lg:w-auto px-8 lg:px-0 py-12 md:py-20 lg:pl-20 ${
                                showDownloadOverlay ? 'lg:pt-[30rem]' : ''
                            } relative text-center lg:text-right bg-white lg:bg-transparent xl:block ${
                                loaded ? 'opacity-100' : 'opacity-0'
                            } transition-all delay-500`}
                        >
                            <div className="inline-block w-full max-w-xl">
                                <div className="w-full pt-9/16 h-56 relative">
                                    <div className="w-full h-full absolute top-0 left-0 z-30 pointer-events-none">
                                        <SVG
                                            src={corner}
                                            className="w-10 h-auto absolute -top-0.5 -left-0.5 text-yellow"
                                        />
                                        <SVG
                                            src={corner}
                                            className="w-10 h-auto absolute -top-0.5 -right-0.5 transform rotate-90 origin-center text-yellow"
                                        />
                                        <SVG
                                            src={corner}
                                            className="w-10 h-auto absolute -right-0.5 -bottom-0.5 transform rotate-180 origin-center text-yellow"
                                        />
                                        <SVG
                                            src={corner}
                                            className="w-10 h-auto absolute -bottom-0.5 -left-0.5 transform -rotate-90 origin-center text-yellow"
                                        />
                                    </div>
                                    <div
                                        className="group asset-clip-box v1 w-full h-full absolute top-0 left-0 z-20"
                                        onClick={handleVideoClick}
                                    >
                                        <div className="asset-clip-box__bar w-2 h-2 absolute top-full right-4 transform translate-y-3 border-2 border-yellow bg-yellow" />

                                        <GatsbyImage
                                            image={thumbnail?.gatsbyImageData}
                                            className="asset-clip-box__box w-full h-full absolute top-0 left-0 bg-darkgrey1 rounded-xl group cursor-pointer bg-cover bg-center bg-norepeat"
                                            style={{ position: 'absolute' }}
                                        />
                                        <PlayButton
                                            color="yellow"
                                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DefaultVideoPanel
